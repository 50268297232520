import { DownOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Select, Space } from 'antd';
import { AddressModel, ErrorModel } from 'digicust_types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../reduxToolkit/store';
import DebouncedInput from './Common/DebouncedInput';
import NumberInput from './Common/NumberInput';
import CountrySelect from './CountrySelect';

const AddressSelect = ({
  value,
  onValueChange = () => null,
  expanded,
  keyValue,
}: {
  value?: AddressModel;
  onValueChange?: (value?: AddressModel) => void;
  expanded?: boolean;
  keyValue?: string;
}) => {
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);

  const streetRef = useRef<InputRef>(null);
  const numberRef = useRef<InputRef>(null);
  const localityRef = useRef<InputRef>(null);
  const postCodeRef = useRef<InputRef>(null);

  const StoreData = useSelector((state: RootState) => state?.caseSlices);
  const warnings = StoreData?.errorsWarnings;
  const errors = StoreData?.errors;
  const field = errors?.field || '';
  const errorField = field.split('.')[1];
  const warningData: ErrorModel = warnings?.find(
    (item) => item?.field === `aggregated.${keyValue}.address`,
  );
  const status = warningData?.severity;

  const handleChange = (key: string, val?: string) => {
    onValueChange({
      ...(value || {}),
      [key]: val,
      ...(key === 'locality' ? { value: val } : {}),
    });
  };

  useEffect(() => {
    if (
      numberRef?.current &&
      keyValue === errorField &&
      errors?.field === `aggregated.${keyValue}.address`
    ) {
      if (
        value?.postalCode ||
        value?.streetName ||
        value?.streetNumber ||
        value?.locality ||
        value?.DE_S0326 ||
        value?.DE_S0347 ||
        value?.additionalIdentifier == errors?.actual
      ) {
        if (numberRef?.current?.input?.id === `number${errorField}`) {
          numberRef?.current?.focus();
          numberRef?.current?.select();
        } else if (streetRef?.current?.input?.id === `vat${errorField}`) {
          streetRef?.current?.focus();
          streetRef?.current?.select();
        } else if (postCodeRef?.current?.input?.id === `name${errorField}`) {
          postCodeRef?.current?.focus();
          postCodeRef?.current?.select();
        } else if (localityRef?.current?.input?.id === `phone${errorField}`) {
          localityRef?.current?.focus();
          localityRef?.current?.select();
        } else null;
      }
    }
  }, [errors]);

  const { t } = useTranslation();

  if (!expanded && Object.keys(value || {}).length === 0) {
    return (
      <Button type="link" onClick={() => onValueChange({ value: ' ' })}>
        {t('Add Address')}
      </Button>
    );
  }

  return (
    <div
      title={warningData?.message}
      style={{
        // backgroundColor: '#d9d9d9',
        width: '100%',
        border: status === 'warning' ? '1px solid #faad14' : '',
        borderRadius: '6px',
      }}
    >
      <Space.Compact style={{ width: '100%' }}>
        <span style={{ width: '50%' }}>
          <DebouncedInput
            ref={streetRef}
            id={`street${keyValue}`}
            placeholder={t('Street')}
            value={value?.streetName}
            onChange={(value) => handleChange('streetName', value)}
          />
        </span>

        <span style={{ width: '50%' }}>
          <DebouncedInput
            ref={numberRef}
            id={`number${keyValue}`}
            placeholder={t('Number')}
            value={value?.streetNumber}
            onChange={(value) => handleChange('streetNumber', value)}
          />
        </span>
      </Space.Compact>

      <Space.Compact style={{ width: '100%' }}>
        <span style={{ width: '40%' }}>
          <CountrySelect
            value={{ alpha2Code: value?.alpha2Code }}
            onChange={(e) => handleChange('alpha2Code', e?.alpha2Code)}
          />
        </span>

        <span style={{ width: '30%' }}>
          <DebouncedInput
            ref={localityRef}
            id={`locality${keyValue}`}
            placeholder={t('Locality')}
            value={value?.locality}
            onChange={(value) => handleChange('locality', value)}
          />
        </span>

        <span style={{ width: '30%' }}>
          <DebouncedInput
            ref={postCodeRef}
            id={`postCod${keyValue}`}
            placeholder={t('Postal Code')}
            value={value?.postalCode ? value?.postalCode : undefined}
            onChange={(e) => handleChange('postalCode', e)}
          />
        </span>

        <Button
          onClick={() => setShowAdvancedFields(!showAdvancedFields)}
          icon={<DownOutlined style={{ fontSize: 11 }} />}
        />
      </Space.Compact>

      {showAdvancedFields && (
        <>
          <Space.Compact style={{ width: '100%' }}>
            <span style={{ width: '40%' }}>
              <Select
                variant="filled"
                options={[
                  { value: 'A', label: t('Determined Location (A)') },
                  { value: 'B', label: t('Approved Location (B)') },
                  { value: 'D', label: t('Other (D)') },
                ]}
                style={{ width: '100%' }}
                placeholder={t('Type of Location')}
                value={value?.DE_S0347}
                onChange={(e) => handleChange('DE_S0347', e)}
              />
            </span>

            <span style={{ width: '30%' }}>
              <Select
                variant="filled"
                options={[
                  { value: 'Y', label: t('Permission (Y)') },
                  { value: 'Z', label: t('Address (Z)') },
                ]}
                style={{ width: '100%' }}
                placeholder={t('Identification')}
                value={value?.DE_S0326}
                onChange={(e) => handleChange('DE_S0326', e)}
              />
            </span>

            <span style={{ width: '30%' }}>
              <DebouncedInput
                placeholder={t('Additional Identifier')}
                value={value?.additionalIdentifier}
                onChange={(e) => {
                  handleChange('additionalIdentifier', e);
                }}
              />
            </span>
          </Space.Compact>
          <Space.Compact style={{ width: '100%' }}>
            <span style={{ width: '100%' }}>
              <DebouncedInput
                placeholder={t('Complement of information')}
                value={value?.complementOfInformation}
                onChange={(e) => {
                  handleChange('complementOfInformation', e);
                }}
              />
            </span>
          </Space.Compact>
        </>
      )}
    </div>
  );
};

export default AddressSelect;
